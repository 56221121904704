exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-418-jsx": () => import("./../../../src/pages/418.jsx" /* webpackChunkName: "component---src-pages-418-jsx" */),
  "component---src-pages-teapot-jsx": () => import("./../../../src/pages/teapot.jsx" /* webpackChunkName: "component---src-pages-teapot-jsx" */),
  "component---src-templates-page-1-jsx": () => import("./../../../src/templates/page1.jsx" /* webpackChunkName: "component---src-templates-page-1-jsx" */),
  "component---src-templates-split-template-jsx": () => import("./../../../src/templates/splitTemplate.jsx" /* webpackChunkName: "component---src-templates-split-template-jsx" */),
  "component---src-templates-state-of-api-2021-split-template-jsx": () => import("./../../../src/templates/stateOfAPI2021splitTemplate.jsx" /* webpackChunkName: "component---src-templates-state-of-api-2021-split-template-jsx" */),
  "component---src-templates-state-of-api-2022-split-template-jsx": () => import("./../../../src/templates/stateOfAPI2022splitTemplate.jsx" /* webpackChunkName: "component---src-templates-state-of-api-2022-split-template-jsx" */),
  "component---src-templates-state-of-api-2023-split-template-jsx": () => import("./../../../src/templates/stateOfAPI2023splitTemplate.jsx" /* webpackChunkName: "component---src-templates-state-of-api-2023-split-template-jsx" */),
  "component---src-templates-state-of-api-2024-template-jsx": () => import("./../../../src/templates/stateOfAPI2024Template.jsx" /* webpackChunkName: "component---src-templates-state-of-api-2024-template-jsx" */)
}

